export const resources = {
    ru: {
      translation: {
        main:{
            reload: 'Загрузка'
        },
        headings: {
          main: 'Для здоровой и активной жизни выбирай Эйфорию.',
          bodySupport: 'Комплексная поддержка организма',
          bioAdds: 'Принимайте биодобавки осознанно',
          referal: ' Получите все привелегии Эйфории',
          whyWe: 'Почему мы?',
          doYouNeedHelp: 'Нужна помощь?',
          
          marketing: 'Маркентинг',
          logistic: 'Логистика',
          callCenter: 'Колл-центр',
          contacts: 'Бизнес-экосистема'
        },
        blocks: {
            header: {
                main: 'Главная',
                products: 'Продукция',
                logistic: 'Логистика',
                contacts: 'О нас',
            },
            mainBlock: {
                subtitle: 'Только природные компоненты. Все средства безопасны для здоровья',
                sale: 'Скидка 30'
            },
            productsBlock :{
                description1: 'Восполняет баланс Омега-3 жирных кислот в организме. Оказывает комплексное антиоксидантное действие на сетчатку глаза, предотвращает синдром «сухого глаза»',
                description2: 'Защищает глаза от усталости, раздражения и нарушений зрения. Поддерживает органы зрения важными питательными веществами, витаминами и микроэлементами',
                description3: 'Помогает расширить и очистить кровеносные сосуды. Стабилизирует деятельность системы кровообращения и работу сердечной мышцы ',
                description4: 'Способствует нормализации уровеня сахара в крови. Снимает симптомы сахарного диабета и снижает вес',
                description5: 'Помогает в решении проблемы с перенесенным простатитом. Для профилактики или лечения острого простатита и аденомы предстательной железы',
                description6: 'Активно борется с стафилококком, стрептококком, гонококком и гельминтами. Благодаря высокому содержанию цинеола, обладает бактерицидными свойствами.',
                description7: 'Снижает аппетит и помогает контролировать чувство голода. Капсулы включают витамины и макроэлементы, и организм получает необходимые ему вещества',
                description8: 'Высокоэффективный препарат - активатор регенерации и роста волос. Препарат способствует укреплению, активному росту волос, и восстанавливает поврежденную структуру, предупреждает избыточное выпадение волос и аллопецию.',
            },
            bioAdds: {
                subtitle1: 'Не уверены, с чего начать? ',
                subtitle2: 'Пройдите онлайн-опрос, чтобы подобрать идеальный набор',
                buttonText: 'Пройти опрос'
            },
            reviewsSlider: {
                text1:'Благодарен Euphoria за их слаженную и быструю работу, логистика на высшем уровне, все очень оперативно доставили!',
                name1: 'Артур Гиясов',
                text2: 'Пропила курс SlimFit результаты не заставили себя ждать, уже через месяц ушли объемы в талии и бедрах. Хорошие показатели и на весах.',
                name2: 'Одина Исломова',
                text3: 'Хочу сказать спасибо команде специалистов, которые грамотно и доходчиво проконсультировали. Очень вежливые и внимательные операторы в call-center.',
                name3: 'Шерзод Махмудов',
                text4: 'Редко пишу отзывы, но после 3 месяцев использования Anfa Oku Vital, глаза после длительного рабочего дня совсем не устают, нет красноты и сухости. Рекомендую!',
                name4: 'Алишер Абдуллаев',
                text5: 'Начала пить «Сустафлекс» ещё в начале мая, через неделю применения чувствуется, что колени уже не ноют на погоду, и вечерние прогулки даются легче.',
                name5: 'Людмила Прохорова'
            },
            referalBlock: {
                subtitle: 'Реферальная платформа создана для того что бы каждый мог зарабатывать на том на чем он будет зарабатывать',
                buttonText: 'Подробнее'
            },
            tabsBlock: {
                title1: 'Надежность',
                subtitle1: 'Мы это - надежность',
                title2: 'Состав',
                subtitle2: 'Наше преимущество - состав',
                title3: 'Эстетика',
                subtitle3: 'Эстетика - это про нас',
                title4: 'Помощь',
                subtitle4: 'Наша команда - специалисты своего дела',
                text1: ' Euphoria на рынке с 2017 года и за это время помогла уже более чем +100.000 человек. Нам доверяют самое дорогое.',
                text2: 'Опираемся на знание природы. Наши технологии основаны на принципах создания биодоступных форм нутриентов, необходимых для обеспечения устойчивого сохранения организма человека.',
                text3: 'Создаем красоту. Мы подошли к тому, чтобы наши продукты не только приносили пользу здоровью, но и доставляли эстетическое удовольствие при использовании.',
                text4: ' Наши специалисты всегда готовы помочь вам в подборе нужного продукта. Мы не придерживаемся принципа «лишь бы продать». Наш ориентир — ваши цели и потребности.',
            },
            contactsBlock: {
                subtitle: 'Если у вас остались дополнительные вопросы или нужна помощь в подборе товара, заполните форму справа и наши специалисты свяжутся с вами в ближайшее время.',
                form:{
                    title: 'Оставьте свою заявку',
                    name: 'Имя',
                    lastName: 'Фамилия',
                    phone: 'Номер телефона',
                    message: 'Ваше сообщение',
                    buttonText: 'Отправить'
                }
            },
            footer: {
                text1: 'Оставить заявку',
                text2: 'Отзывы',
                text3: 'Продукты',
                text4: 'Новые продукты',
                text5: 'О нашем бизнесе',
                text6: 'Партнеры',
                text7: 'Для клиентов',
                text8: 'Главная'
            },
            logisticsBlock: {
                subtitle: 'Мы гордимся нашими филиалами в трех странах, что позволяет обеспечить быструю и надежную доставку наших БАДов по всей стране. Наша логистическая сеть гарантирует, что ваш заказ прибудет в кратчайшие сроки. Независимо от вашего местоположения, вы можете быть уверены в качестве и своевременности доставки наших продуктов.',
                btnText: 'Главная'
            },
            callCenterBlock: {
                subtitle: ' Мы гордимся тем, что каждый день меняем жизнь наших клиентов к лучшему и поддерживаем их в стремлении к здоровому образу жизни и поддержки своего организма. Наш колл-центр и квалифицированные специалисты всегда готовы помочь вам в подборе нужного вам товара и ответит на любые интересующие вас вопросы, касающиеся нашей продукции и компании.',
                btnText: 'Главная'
            },
            marketingBlock: {
                subtitle: ' Добро пожаловать в мир Euphoria Group! Бизнес – это одна общая миссия: помогать людям вести здоровый образ жизни, работая вместе, чтобы предоставлять высококачественные продукты и услуги, которые приносят пользу.',
                btnText: 'Главная'
            },
            contacts: {
                subtitle: 'Мы предлагаем широкий ассортимент товаров и имеем офисы в трех странах. Для вашего удобства мы также открыли интернет-магазин, который обеспечивает доставку по всей стране. Это позволит вам полностью сосредоточиться на заботе о себе, не отвлекаясь на поиск необходимых вещей и их приобретение.',
                form: {
                    title: 'Свяжитесь с нами!',
                    location: 'Узбекистан; Ташкент; Юнусабадский район; улица Ахмада Дониша 78А',
                    name: 'Имя',
                    lastName: 'Фамилия',
                    phone: 'Номер телефона',
                    buttonText: 'Отправить',
                    massage: 'Ваше сообщение',
                    city: 'Город',
                    price: 'Общая стоимость',
                    accepted: 'Заявка принята',
                    orderText: ' Ваш заказ',
                    quantityAlert: 'Выберите количество товара!',
                    fillInputs: 'Заполните все поля!',
                      contactYou: 'Наш сотрудник свяжется с вами',
                 soon: 'в ближайшее время!'
                }
            },
            catalogBlock: {
                title: 'Каталог',
                subtitle: 'Ваше здоровье - наша миссия',
                categoryBtn: 'Категории',
                newsBtn: 'Новинки',
                viewAll: ' Посмотреть все',
                price: 'Цена',
                btnBuy: 'Купить',
                buy: 'Заказать',
                quantity: 'Количество',
                shop: 'Магазин',
                addMaterial: 'Дополнительные сведения',
                contraction: 'Противопоказания',
                testimony: 'Показания',
                action: 'Действие препарата',
                 compaund: 'Состав',
                 order: 'Оформить заказ',
            }
        },
        tests:{
            textAlert:{
                alert1: 'Выберите категорию заболевания!',
                alert2: 'Выберите один из вариантов ответа!',
                alert3: 'Рекомендованный продукт откроется через 3 секунды!',
                alert4: 'Заявка была успешно отправленна',
                alert5: 'Что-то пошло не так, повторите попытку.'
            },
            
            btnText:{
                next: 'Следующий',
                theEnd: 'Сдать'
            },
            optionTitle: {
                title1 : ' Категория заболевания',
                title2: 'Возраст',
                title3: 'Ваш возраст?',
                title4: 'Выберите ответ '
            },
            valueAge: {
                value1: '18-30 лет',
                value2: '30-45  лет',
                value3: '45-65 лет',
                value4: '65 и больше',
            },
            first:{
                name: 'Гипертония',
                surveyQuestions:{
                    first:{
                        title: "Укажите ваш пол: ",
                        q1: 'Мужчина',
                        q2: 'Женщина'
                    },
                    second: {
                        title: 'Выберете категорию заболевания:',
                        q1: "Сердце",
                        q2: "Диабет",
                        q3: "Потенция",
                        q4: "Похудение",
                        q5: "Зрение",
                        q6: "Потоотделение",
                    },
                    third:{
                        title: 'Какой образ жизни вы ведете?',
                        q1: "Активный",
                        q2: "Малоактивный",
                        q3: "Сидячий",
                    },
                    forth: {
                        title: 'Как часто вы употребляете жаренное?',
                        q1: "Часто",
                        q2: "Иногда",
                        q3: "Редко",
                        q4: "Не употребляю ",
                    },
                    fifth: {
                        title: 'Курите ли вы?(Электронные сигареты в том числе)',
                        q1: "Да",
                        q2: "Иногда",
                        q3: "Нет",
                    },
                    sixth: {
                        title: "Сколько часов вы спите?",
                        q1: "7 и более часов",
                        q2: "Менее 8 часов",
                        q3: "Менее 6 часов",
                    }
                }
            },
            second:{
                name: 'Гипертония',
                surveyQuestions:{
                    first:{
                        title: "Укажите ваш пол: ",
                        q1: 'Мужчина',
                        q2: 'Женщина'
                    },
                    second: {
                        title: 'Выберете категорию заболевания:',
                        q1: "Сердце",
                        q2: "Диабет",
                        q3: "Потенция",
                        q4: "Похудение",
                        q5: "Зрение",
                        q6: "Потоотделение",
                    },
                    third:{
                        title: 'Какой образ жизни вы ведете?',
                        q1: "Активный",
                        q2: "Малоактивный",
                        q3: "Сидячий",
                    },
                    forth: {
                        title: 'Как часто вы употребляете жаренное?',
                        q1: "Часто",
                        q2: "Иногда",
                        q3: "Редко",
                        q4: "Не употребляю ",
                    },
                    fifth: {
                        title: 'Курите ли вы?(Электронные сигареты в том числе)',
                        q1: "Да",
                        q2: "Иногда",
                        q3: "Нет",
                    },
                    sixth: {
                        title: "Сколько часов вы спите?",
                        q1: "7 и более часов",
                        q2: "Менее 8 часов",
                        q3: "Менее 6 часов",
                    }
                }
            },
        },
        timeline: {
            title:'О нас',
            link: 'Подробнее',
            year1: 'Год основания компании Штаб всего из 12 человек. Один небольшой офис.',
            year2: 'Заметный рост. Открытие филиала в Казахстане. В штабе 100+ человек.',
            year3: ' Уверенно заявляем о себе. Открытие филиала в Турции. В штабе 250+ человек.',
            year4: 'Филиалы в 3 странах. Выстроенная бизнес-экосистема, бесперебойная логистика, производство. В штабе 600+ человек.'
        }
      },
    },
    uz: {
        translation: {
            main:{
                reload: 'Юкланмоқда'
            },
            headings: {
                main: 'Соғлом ва фаол ҳаёт учун ейфорияни танланг.',
                bodySupport: 'Танани комплекс қўллаб-қувватлаш',
                bioAdds: 'Қўшимчаларни онгли равишда олинг',
                referal: 'Ейфориянинг барча имтиёзларини олинг',
                whyWe: 'Нега биз?',
                doYouNeedHelp: 'Сизга ёрдам керакми?',

                marketing: 'Маркентинг',
                logistic: 'Логистика',
                callCenter: 'Колл сентер' ,
                contacts: 'Бизнес екотизими'
            },
            blocks: {
                header: {
                    main: 'Асосий',
                    products: 'Маҳсулотлар',
                    logistic: 'Логистика',
                    contacts: 'Биз ҳақимизда',
                },
                mainBlock: {
                    subtitle: 'Фақат табиий ингредиентлар. Барча маҳсулотлар соғлиқ учун хавфсиздир',
                    sale: '30% чегирма'
                },
                productsBlock :{
                    description1: 'Танадаги Omega-3 ёг ъ кислоталари мувозанатини тўлдиради. Кўзнинг тўр пардасига мураккаб антиоксидант таъсир кўрсатади, қуруқ кўз синдромининг олдини олади',
                    description2: 'Кўзларни чарчоқ, тирнаш хусусияти ва кўриш бузилишидан ҳимоя қилади. Кўриш органларини муҳим озиқ моддалар, витаминлар ва из елементлари билан қўллаб-қувватлайди',
                    description3: 'Бу қон томирларини кенгайтириш ва тозалашга ёрдам беради. Қон айланиш тизимининг фаолиятини ва юрак мушакларининг ишини барқарорлаштиради',
                    description4: 'Қон шакар даражасини нормаллаштиришга ёрдам беради. Қандли диабет аломатларини енгиллаштиради ва вазнни камайтиради',
                    description5: 'Бу простатит муаммосини ҳал қилишга ёрдам беради. Ўткир простатит ва простата аденомасининг олдини олиш ёки даволаш учун',
                    description6: 'У стафилококк, стрептококк, гонококк ва гелминтларга қарши фаол курашади. Синеолнинг юқори миқдори туфайли у бактерицид хусусиятларига ега.',
                    description7: 'Иштаҳани пасайтиради ва очликни назорат қилишга ёрдам беради. Капсулаларга витаминлар ва из елементлари киради ва организм керакли моддаларни олади',
                    description8: 'Yuqori samarali dori - sochlarning yangilanishi va osishi faollashtiruvchisi. Preparat sochlarning mustahkamlanishiga, faol osishiga yordam beradi va shikastlangan tuzilmani tiklaydi, ortiqcha soch tokilishi va alopesiyaning oldini oladi.',
                },
                bioAdds: {
                    subtitle1: 'Қаердан бошлашни билмаяпсизми?',
                    subtitle2: 'Мукаммал тўпламни топиш учун онлайн сўров ўтказинг',
                    buttonText: 'Сўровни олинг'
                },
                reviewsSlider: {
                    text1:'Мен уларнинг яхши мувофиқлаштирилган ва тезкор иши учун ейфориядан миннатдорман, логистика енг юқори даражада, ҳамма нарса жуда тез етказиб берилди!',
                    name1: 'Artur Гиясов',
                    text2: 'Мен Слимфит курсини ичдим, натижалар узоқ кутилмади, бир ойдан кейин бел ва сонлардаги ҳажмлар йўқолди. Яхши кўрсаткичлар ҳам тарозида.',
                    name2: 'Одина Исломова',
                    text3: 'Ваколатли ва Равшан маслаҳат берган мутахассислар жамоасига раҳмат айтмоқчиман. Cалл-марказ операторлари жуда мулойим ва диққатли.',
                    name3: 'Шерзод Маҳмудов',
                    text4: 'Мен камдан-кам шарҳлар ёзаман, лекин Анна Ака Vital-дан 3 ой фойдалангандан сўнг, узоқ иш кунидан кейин кўзлар умуман чарчамайди, қизариш ва қуруқлик йўқ. Мен буни тавсия қиламан!',
                    name4: 'Алишер Абдуллаев',
                    text5: 'Мен Сустафлехни май ойининг бошларида ичишни бошладим, бир ҳафталик фойдаланишдан кейин тиззаларим об-ҳаво учун оғриётгандек туюлади ва кечқурун юриш осонроқ.',
                    name5: 'Людмила Прохорова'
                },
                referalBlock: {
                    subtitle: 'Йўналтирувчи платформа ҳар ким ўзи бўладиган нарсадан пул топиши учун яратилган қозон',
                    buttonText: 'Батафсил'
                },
                tabsBlock: {
                    title1: 'Ишончлилик',
                    subtitle1: 'Биз ишончлиликмиз',
                    title2: 'Таркиби',
                    subtitle2: 'Бизнинг устунлигимиз-бу композиция',
                    title3: 'Естетика',
                    subtitle3: 'Естетика биз ҳақимизда',
                    title4: 'Ёрдам',
                    subtitle4: 'Бизнинг жамоамиз ўз соҳасининг мутахассислари',
                    text1: 'Ефория бозорда 2017 йилдан бери мавжуд ва шу вақт ичида 100 000 дан ортиқ одамга ёрдам берган. Улар бизга енг қимматли нарсаларга ишонишади.',
                    text2: 'Биз табиат ҳақидаги билимларга таянамиз. Бизнинг технологияларимиз инсон танасининг барқарор сақланишини таъминлаш учун зарур бўлган озуқа моддаларининг биологик мавжуд шаклларини яратиш тамойилларига асосланади.',
                    text3: 'Биз гўзалликни яратамиз. Биз маҳсулотларимиз нафақат соғлиқ учун фойда келтириши, балки фойдаланилганда естетик завқ бағишлашини таъминлаш учун келдик.',
                    text4: 'Бизнинг мутахассисларимиз ҳар доим тўғри маҳсулотни танлашда сизга ёрдам беришга тайёр. Биз "фақат сотиш" тамойилига амал қилмаймиз. Бизнинг маълумот нуқтамиз сизнинг мақсадларингиз ва еҳтиёжларингиздир.',
                },
                contactsBlock: {
                    subtitle: 'Агар сизда қўшимча саволларингиз бўлса ёки маҳсулотни танлашда ёрдамга муҳтож бўлсангиз, ўнгдаги шаклни тўлдиринг ва бизнинг мутахассисларимиз имкон қадар тезроқ сиз билан боғланишади.',
                    form:{
                        title: 'Сўровингизни қолдиринг',
                        name: 'Номи',
                        lastName: 'Фамилияси',
                        phone: 'Telefon рақами',
                        message: 'Сизнинг хабарингиз',
                        buttonText: 'Юбориш'
                    }
                },
                footer: {
                    text1: 'Аризангизни юборинг',
                    text2: 'Шарҳлар',
                    text3: 'Маҳсулотлар',
                    text4: 'Янги маҳсулотлар',
                    text5: 'Бизнинг бизнесимиз ҳақида',
                    text6: 'Ҳамкорлар',
                    text7: 'Мижозлар учун',
                    text8: 'Асосий'
                },
                logisticsBlock: {
                    subtitle: 'Биз учта мамлакатдаги филиалларимиз билан фахрланамиз, бу бизга хун таквиясини бутун мамлакат бўйлаб тез ва ишончли етказиб беришни таъминлаш имконини беради. Бизнинг логистика тармоғимиз сизнинг буюртмангиз имкон қадар тезроқ келишини таъминлайди. Жойлашувингиздан қатъи назар, сиз маҳсулотимизнинг сифати ва ўз вақтида етказиб берилишига амин бўлишингиз мумкин.',
                    btnText: 'Асосий'
                },
                callCenterBlock: {
                    subtitle: 'Биз ҳар куни мижозларимизнинг ҳаётини яхши томонга ўзгартирганимиздан ва уларни соғлом турмуш тарзи ва танасини қўллаб-қувватлашда қўллаб-қувватлаётганимиздан фахрланамиз. Бизнинг cалл-марказимиз ва малакали мутахассисларимиз сизга керакли маҳсулотни танлашда ёрдам беришга ва маҳсулотимиз ва компаниямиз билан боғлиқ барча саволларингизга жавоб беришга доимо тайёр.',
                    btnText: 'Асосий'
                },
                marketingBlock: {
                    subtitle: ' Ейфория гуруҳига хуш келибсиз! Бизнес-бу умумий вазифалардан бири: одамларга фойдали сифатли маҳсулотлар ва хизматларни тақдим етиш учун биргаликда ишлаш орқали соғлом турмуш тарзини олиб боришга ёрдам бериш.',
                    btnText: 'Асосий'
                },
                contacts: {
                    subtitle: 'Биз маҳсулотлар кенг доирадаги таклиф ва уч мамлакатда ваколатхонасини бор. Сизга қулайлик учун, биз ҳам Биз бутун мамлакат бўйлаб етказиб беришни таъминлайдиган онлайн-дўкон очдик. Бу сизга тўлиқ имкон беради керакли нарсаларни топиш ва уларни сотиб олиш билан чалғимасдан ўзингизга ғамхўрлик қилишга еътибор қаратинг.',
                    form: {
                        title: 'Биз билан боғланинг!',
                        location: 'Ўзбекистон; Тошкент; Юнусобод тумани; Ahmad Дониш кўчаси 78а',
                        name: 'Номи',
                        lastName: 'Фамилияси',
                        phone: 'Telefon рақами',
                        buttonText: 'Юбориш',
                        massage: 'Сизнинг хабарингиз',
                        city: 'Шаҳар',
                        price: 'Умумий қиймати',
                        accepted: 'Ариза қабул қилинди',
                        orderText: 'Сизнинг буюртмангиз',
                        contactYou: 'Бизнинг ходимимиз сиз билан боғланади',
                        soon: 'Келмоқда!',
                        quantityAlert: 'Маҳсулот миқдорини танланг!',
                        fillInputs: 'Барча майдонларни тўлдиринг!'
                    }
                },
                catalogBlock: {
                    title: 'Каталог',
                    subtitle: 'Сизнинг соғлиғингиз бизнинг вазифамиздир',
                    categoryBtn: 'Категориялар',
                    newsBtn: 'Янги маҳсулотлар',
                    viewAll: 'Ҳаммасини кўриш',
                    price: 'Нархи',
                    btnBuy: 'Сотиб олиш',
                    buy: 'Буюртма бериш',
                    quantity: 'Миқдори',
                    shop: 'Дўкон',
                    addMaterial: 'Қўшимча маълумот',
                    contraction: 'Контрендикациялар',
                    testimony: 'Кўрсаткичлар',
                    action: 'Препаратнинг таъсири',
                    compaund: 'Таркиби',
                    order: 'Буюртма беринг'
                    
                }
            },
            tests:{
                textAlert:{
                    alert1: 'Касаллик тоифасини танланг!',
                    alert2: 'Жавоб вариантларидан бирини танланг!',
                    alert3: 'Тавсия етилган маҳсулот 3 сонияда очилади!',
                    alert4: 'Ариза муваффақиятли топширилди',
                    alert5: 'Нимадир нотўғри кетди, яна уриниб кўринг.'
                },
                btnText:{
                    next: 'Кейинги',
                    theEnd: 'Ўтиш'
                },
                optionTitle: {
                    title1 : ' Касаллик тоифаси',
                    title2: 'Yoshi',
                    title3: 'Сизнинг ёшингиз?',
                    title4: 'Жавобни танланг'
                },
                valueAge: {
                    value1: '18-30 йиллар',
                    value2: '30-45  йиллар',
                    value3: '45-65 йиллар',
                    value4: '65 ва ундан кўп',
                },
                first:{
                    name: 'Гипертензия',
                    surveyQuestions:{
                        first:{
                            title: "Жинсингизни белгиланг: ",
                            q1: 'Одам',
                            q2: 'Аёл'
                        },
                        second: {
                            title: 'Касаллик тоифасини танланг:',
                            q1: "Юрак",
                            q2: "Қандли диабет",
                            q3: "Потенциал",
                            q4: "Вазн йўқотиш",
                            q5: "Кўриш",
                            q6: "Терлаш",
                        },
                        third:{
                            title: 'Сиз қандай турмуш тарзини олиб борасиз?',
                            q1: "Фаол",
                            q2: "Нофаол",
                            q3: "Sedentary",
                        },
                        forth: {
                            title: 'Қовурилган овқатни қанчалик тез-тез истеъмол қиласиз?',
                            q1: "Тез-тез",
                            q2: "Баъзан",
                            q3: "Камдан кам",
                            q4: "Мен фойдаланмайман",
                        },
                        fifth: {
                            title: 'Сиз чекасизми?(Шу жумладан електрон сигареталар)',
                            q1: "Ҳа",
                            q2: "Баъзан",
                            q3: "Йўқ",
                        },
                        sixth: {
                            title: "Сиз неча соат ухлайсиз?",
                            q1: "7 ёки ундан кўп соат",
                            q2: "8 соатдан кам",
                            q3: "6 соатдан кам",
                        }
                    }
                },
                second:{
                    name: 'Гипертензия',
                    surveyQuestions:{
                        first:{
                            title: "Жинсингизни белгиланг: ",
                            q1: 'Одам',
                            q2: 'Аёл'
                        },
                        second: {
                            title: 'Касаллик тоифасини танланг:',
                            q1: "Юрак",
                            q2: "Қандли диабет",
                            q3: "Потенциал",
                            q4: "Вазн йўқотиш",
                            q5: "Кўриш",
                            q6: "Терлаш",
                        },
                        third:{
                            title: 'Сиз қандай турмуш тарзини олиб борасиз?',
                            q1: "Фаол",
                            q2: "Нофаол",
                            q3: "Sedentary",
                        },
                        forth: {
                            title: 'Қовурилган овқатни қанчалик тез-тез истеъмол қиласиз?',
                            q1: "Тез-тез",
                            q2: "Баъзан",
                            q3: "Камдан кам",
                            q4: "Мен фойдаланмайман",
                        },
                        fifth: {
                            title: 'Сиз чекасизми?(Шу жумладан електрон сигареталар)',
                            q1: "Ҳа",
                            q2: "Баъзан",
                            q3: "Йўқ",
                        },
                        sixth: {
                            title: "Сиз неча соат ухлайсиз?",
                            q1: "7 ёки ундан кўп соат",
                            q2: "8 соатдан кам",
                            q3: "6 соатдан кам",
                        }
                    }
                },
            },
            timeline: {
                title:'Биз ҳақимизда',
                link: 'Батафсил',
                year1: 'Компания ташкил етилган йили ходимлар атиги 12 кишидан иборат еди. Битта кичик офис.',
                year2: 'Сезиларли ўсиш. Қозоғистонда филиали очилиши. Бош қароргоҳда 100 + kishi бор.',
                year3: ' Биз ўзимизни ишонч билан еълон қиламиз. Туркияда филиали очилиши. Бош қароргоҳда 250 + kishi бор.',
                year4: 'Филиаллари 3 мамлакатлар. Яхши қурилган бизнес екотизими, узлуксиз логистика ва ишлаб чиқариш. Бош қароргоҳда 600 + kishi бор.'
            }
        }
    }
  };